<template>
  <v-dialog
    width="400"
    :value="show"
    @click:outside="cancel"
    @keydown="handleKeydown"
  >
    <v-card v-if="value">
      <v-form
        v-model="form.valid"
        class="text-center"
        @submit.prevent="handleSubmit"
      >
        <v-card-title>{{ title }}</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                clearable
                required
                clear-icon="$delete"
                autocomplete="off"
                class="validation"
                :counter="100"
                label="Name"
                v-model="name"
                :rules="form.rules.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                clearable
                required
                clear-icon="$delete"
                autocomplete="off"
                class="validation"
                :counter="100"
                label="E-Mail-Adresse"
                v-model="email"
                :rules="form.rules.email"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <temeno-circle-button action title="abbrechen" @click="cancel">
            $close
          </temeno-circle-button>

          <temeno-circle-button
            action
            title="speichern"
            type="submit"
            :disabled="!form.valid"
          >
            {{ !form.valid ? '$approve_action_disabled' : '$approve' }}
          </temeno-circle-button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmail } from "@/functions";

export default {
  name: "ContactEditor",
  components: {
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      form: {
        rules: {
          name: [(v) => (v && v.length > 0) || this.$t("validation.mandatory")],
          email: [(v) => isEmail(v) || this.$t("validation.email")],
        },
        valid: false,
      },
      email: null,
      id: null,
      name: null,
    };
  },
  computed: {
    title() {
      return this.id != null ? "Kontakt bearbeiten" : "Neuen Kontakt erstellen";
    },
  },
  watch: {
    value() {
      if (this.value) {
        const { email, id, name } = this.value;
        this.email = email;
        this.id = id;
        this.name = name;
      } else {
        this.email = null;
        this.id = null;
        this.name = null;
      }
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    handleKeydown(key) {
      if (key.code === "Escape") {
        this.cancel();
      }
    },
    handleSubmit() {
      const { email, id, name } = this;
      this.$emit("input", { email, id, name });
    },
  },
  i18n: {
    messages: {
      de: {
        validation: {
          email: "Bitte geben Sie eine gültige E-Mail-Adresse an",
          mandatory: "Dies ist ein Pflichtfeld",
        },
      },
    },
  },
};
</script>
